export enum GoldStarTextBoxStylingMode {
	Outlined = 'outlined',
	Filled = 'filled',
	Underlined = 'underlined',
}

export enum GoldstarTextBoxLabelMode {
	Floating = 'floating',
	Static = 'static',
	Hidden = 'hidden',
}

export enum TippyComponentInstance {
	Favourite = 'instance-12',
	MyDay = 'my-day-popup-instance',
	Feedback = 'feedback',
	GsConnect = 'gs-connect',
}

export enum GridFilterValues {
	ViewAllCheckbox = 'viewAllCheckbox',
	ViewAssignedCheckbox = 'viewAssignedCheckbox',
	ViewCompletedTicketsCheckbox = 'viewCompletedTicketsCheckbox',
	ToDoEventCheckbox = 'toDoEventCheckbox',
	JobResultTypeDropdown = 'jobResultTypeDropdown',
	JobDropdown = 'jobDropdown',
	JobTypeDropdown = 'jobTypeDropdown',
	LoanOfficerDropDown = 'loanOfficerDropDown',
	ViewCancelledDeniedRequestsCheckbox = 'viewCancelledDeniedRequestsCheckbox',
	ViewCanceledPreviousCheckbox = 'viewCanceledChecked',
	ViewProductionProjects = 'viewProductionProjects',
	ViewCanceledProject = 'viewCanceledProject',
	ViewWithdrawnProjects = 'viewWithdrawnProjects',
	ViewRequestedProjects = 'viewRequestedProjects',
}
